<template>
  <!-- 明細 -->
  <div class="billDetail">
    <div class="addPerson__wrap" v-show="visible">
      <el-header class="shadow page-header__wrap">
        <div class="flex">
          <div class="backBtn" @click="handleReturn">
            <el-image class="back-img" :src="require('@/assets/img/back.png')"></el-image>
            <span>返回</span>
          </div>
          <div class="title">
            <span class="site">当前位置：</span>
            {{ $route.meta.title }}
            <span> > </span>
            供应商账单明细
          </div>
        </div>
      </el-header>
      
      <div class="main">
        <div class="mainBox">
          <div style="display:flex;align-items: center;margin-bottom:10px;font-size:14px;">
            <div>
              <span style="color:#999;">供应商名称：</span>
              <span style="color:#909399;font-weight:bold;">{{currRow.supplier_name}}</span>
            </div>
            <div style="margin-left:20px;">
              <span style="color:#999;">交货日期：</span>
              <el-date-picker
                v-model="table.params.day"
                type="date"
                size="mini"
                style="width:240px;"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
                @change="getTable">
              </el-date-picker>
            </div>
          </div>
          <el-table
            :data="table.data"
            style="width: 100%"
            :row-key="row => row.id"
            border
            size="mini"
            :header-cell-style="{background:'#f5f5f5'}">
            <el-table-column
              type="index"
              label="序号"
              width="60">
            </el-table-column>
            <el-table-column property="date" label="交货日期"></el-table-column>
            <el-table-column property="purchase_count" label="收货采购单/笔"></el-table-column>
            <el-table-column property="total_order_num" label="下单数量"></el-table-column>
            <el-table-column property="total_delivery_num" label="收货数量"></el-table-column>
            <el-table-column property="total_delivery_money" label="收货金额(元)"></el-table-column>
            <el-table-column
              label="操作"
              prop="action"
              width="100">
              <template slot-scope="{row}">
                <el-button type="text" @click="showDetail(row)">查看收货</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <el-dialog title="采购单收货信息" width="800px" class="dialog-box" center :modal="false" :close-on-click-modal="false" :modal-append-to-body="false" :visible.sync="dialogTableVisible">
      <el-table :data="tableData" border>
        <el-table-column
          type="index"
          label="序号"
          width="60">
        </el-table-column>
        <el-table-column property="order_no" label="采购单号" width="190">
          <template slot-scope="{row}">
            <el-link type="primary" @click="showDetailPurchase(row)" >{{row.order_no}}</el-link>
          </template>
        </el-table-column>
        <el-table-column property="spec_count" label="商品项数"></el-table-column>
        <el-table-column property="total_order_num" label="下单数量"></el-table-column>
        <el-table-column property="total_delivery_num" label="收货数量"></el-table-column>
        <el-table-column property="total_delivery_money" label="收货金额(元)"></el-table-column>
      </el-table>
    </el-dialog>

    <!-- 采购单详情 -->
    <PurchaseDetail ref="purchasedetail" ></PurchaseDetail>
  </div>
</template>

<script>
  import PurchaseDetail from '@/views/purchase/list/components/Details.vue'
  import { mixinTable } from '@/mixins/table.js'
  export default {
    name: 'BuillDetail',
    props:['sleDate'],
    mixins: [mixinTable],
    components: {
      PurchaseDetail
    },
    data() {
      return {
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        table: {
          params:{
            page:1,
            count:10,
            day: '',
          },
          total: 0,
          data:[],
        },
        currRow: {},
        dialogTableVisible: false,
        tableData: [],
      }
    },
    methods: {
      getDetail(row) {
        this.currRow = row;
        this.getTable()
      },
      reset(done) {
        this.isChange = false;
        this.table.params.day = '';
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 单品汇总详情
      getTable() {
        let _params = {
         month: this.currRow.month, // 下单月份
         supplier_id: this.currRow.bind_id, // 供应商id
         day: this.table.params.day, // 下单日期
        }
        this.$http.get("/admin/bill/info",{params: _params}).then((res) => {
          if (res.code === 1) {
            this.table.loading = false;
            this.table.data = res.data.list
            this.table.total = res.data.total;
          }
        })
      },
      //查看收货
      showDetail(row) {
        this.dialogTableVisible = true;
        let _params = {
          supplier_id: this.currRow.bind_id, // 供应商ID
          day: row.date,
          page: this.table.params.page,
          count: this.table.params.count,
        }
        this.$http.get('/admin/bill/delivery',{params: _params}).then(res => {
          if(res.code == 1) {
            this.tableData = res.data.list;
            
          }
        })
      },
       // 采购单详情
      showDetailPurchase(row) {
        // this.dialogTableVisible = false;
        let dom = this.$refs.purchasedetail;
        let obj = {
          id: row.purchase_id,  //采购单id
          showType: 9999, // 公用组件，不展示底部操作按钮
        }
        dom.toggle(true);
        dom.getDetail(obj);
        dom = null;
      },
      // 返回
      handleReturn() {
        this.$emit("refresh")
        this.toggle(false)
      },
      
     
    }
  }
</script>

<style scoped lang="scss">
.addPerson__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
} 
.addPerson__wrap .main {
  overflow-x: hidden;
  background: #fff;
}
.returnBtn {
  z-index: 99;
  box-shadow: 0 0 8px 0 rgb(232 237 250 / 60%), 0 2px 4px 0 rgb(232 237 250 / 50%);
}
.footerWrap {
  text-align: center;
  padding: 10px;
   box-shadow: 0 0 8px 0 rgb(232 237 250 / 60%), 0 2px 4px 0 rgb(232 237 250 / 50%);
  z-index: 99;
}
.itemTime {
  display: block;
  margin-bottom: 10px;
  height: 30px;
  line-height: 30px;
}
.itemTime::before {
  content: "";
  width: 5px;
  height: 15px;
  background: #2cb167;
  border-radius: 6px;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 2px;
}
.flex {
  display: flex;
}
.mt20 {
  margin-top: 20px;
}
.mb20 {
  margin-bottom: 20px;
}
.width200px {
  width: 200px;
}
.after-table {
  padding: 10px;
  font-size: 13px;
  color: #303030;
  text-align: right;
}
.pageWrap {
  margin-top: 10px;
  text-align: right;
  margin-right: 36px;
}
.dialog-box {
  z-index: 8 !important;
}

.billDetail ::v-deep  .v-modal {
  z-index: 7 !important;
}
</style>
